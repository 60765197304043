import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchHotlines(ctx, params) {
      return useJwt.getHotlines(params).then(response => response);
    },
    createHotline(ctx, data) {
      return useJwt.createHotline(data).then(response => response);
    },
    updateHotline(ctx, data) {
      return useJwt.updateHotline(data).then(response => response);
    },
    getHotlineDetail(ctx, id) {
      return useJwt.getHotlineDetail(id).then(response => response);
    },
    deleteHotlines(ctx, data) {
      return useJwt.deleteHotlines(data).then(response => response);
    },
  },
};
